
.tableRow {
    border: silver 1px solid;
}

.card-contrast {
    background-color: rgb(221, 220, 220);
}





.icon-mini {
    width: 20px;
}

ion-accordion {
    margin: 0 auto;
  }
  
.playerAvatar {
    float: left;
    margin: 0 5px;
    width: 35px;
    height: 35px;
}

.recurringIcon{
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    margin-left: 20px !important;
}



.bigAvatar {

    width: 200px;
    height: 200px;
    margin: 0 auto;

}

