ion-button.dragon {
    height: 100%;
    width:100%;
    --ripple-color: transparent
}
ion-button.dragon img {
    opacity: 60%;
    border-radius: 5px;
}
.dragonSelected {
    opacity: 100% !important;
    -moz-box-shadow:    0px 0px 5px 5px #a84949;
    -webkit-box-shadow: 0px 0px 5px 5px #a84949;
    box-shadow:         0px 0px 5px 5px #a84949;
    --ripple-color: none;
}

.hexagon-wrapper {
    width: 60px;
    height: 60px;
    text-align: center;
    margin: 10px;
    position: relative;
    display: inline-block;
  }
  
.hexagon {
    height: 100%;
    width: calc(100% * 0.57735);
    display: inline-block;
}
.hex-label {
    position: absolute;
    top: 20px;
    text-align: center;
    width: 60px;
    color: #000;
  }
.hex-label.white {color: #fff}
.hexagon:before {
    position: absolute;
    top: 0;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    background-color: inherit;
    height: inherit;
    width: inherit;
    content: '';
    transform: rotateZ(60deg);
}
  
.hexagon:after {
    position: absolute;
    top: 0;
    right: calc((100% / 2) - ((100% * 0.57735) / 2));
    background-color: inherit;
    height: inherit;
    width: inherit;
    content: '';
    transform: rotateZ(-60deg);
}
.hex1 {
    background-color: #036318;
    color:  #fff;  
}
.hex2 {
    background-color:rgb(180, 165, 32);
}
.hex3 {
    background-color: rgb(128, 18, 18);
    color:  #fff;  
}