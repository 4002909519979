@media only screen and (max-width: 600px) {
    .choice-button{
        height: 100%
        
    }
    .choice {
        font-size: 10pt;
        
    }
  }
  @media only screen and (min-width: 601px) {
    .choice-button{
        height: 100%
    }


  }



 /* border element images */
 .border-element.small-content.air {
    background-image: url("/public/assets/img/elements-borders/air.jpg");

 }
 .border-element.small-content.fire {
   background-image: url("/public/assets/img/elements-borders/fire.jpg");
   
}
.border-element.small-content.nature {
  background-image: url("/public/assets/img/elements-borders/nature.jpg");
  
}
.border-element.small-content.water {
   background-image: url("/public/assets/img/elements-borders/water.jpg");

}

.story-attribute-icon {
    height: 50px;
}



p.choice-cost {
    font-size: 8pt;
    margin: 3px 0 5px 0;
}
p.choice-cost span{
    font-size: 8pt;
    
}

img.icon-cost {
    height: 30px;
    margin-bottom: 10px;
}

.attribute-content {
    display: flex;
}

.positive {
    color: black !important;
    background-color: white !important;
    --background: white !important;
}

.negative {
    color: white !important;
    background-color: black !important;
    --background: black !important;
}