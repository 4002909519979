.adventure-background {
    height: 100%;
    width:100%;
    
}
.adventure-background img{
    border-radius: 5px;
}
.adventure-background:hover {
    opacity: 90%;
}
.adventure-background p {
position: absolute;
color: #fff;
font-size: 20pt;
white-space: normal;
margin: 10px;
text-shadow: 2px 2px #000000;
}

@media only screen and (max-width: 600px) {
.adventure-background p {
    position: absolute;
    color: #fff;
    font-size: 14pt;
    white-space: normal;
    margin: 5px;
    text-shadow: 2px 2px #000000;
    }
}